.pagination {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  border: lightgray 1px solid;
  border-radius: 6px;
  background: white;
  color: #2563EB;
  font-weight: 500;

  .page-item, .break {
    &.active {
      background-color: #2563EB;
      border-color: transparent;
      color: white;
    }

    &:not(:first-child) {
      border-left: lightgray 1px solid;
    }
  }

  .page-item {
    padding: 0.5em 0.75em;
  }

  .break {
    padding: 0.5em 0.65em;
  }

  .page-link {
    cursor: pointer;
  }

  .disabled {
    cursor: default;
    color: grey;
  }
}
