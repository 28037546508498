@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Inter";
    src: url("../font/Inter-VariableFont.ttf");
}

html,
body {
    @apply font-inter antialiased;
}

.btn-primary {
    @apply bg-sky-blue text-white;
    @apply hover:bg-sky-blue-light;
    @apply transition-colors
}

.btn-secondary {
    @apply bg-sky-blue-light text-white;
}

.btn-primary-ghost {
    @apply border-sky-blue border bg-none text-sky-blue;
}

.btn-secondary-ghost {
    @apply border-sky-blue-light bg-none text-sky-blue;
}

:disabled {
    cursor: not-allowed;
}

.react-datepicker__navigation--previous {
    @apply mt-2;
}

.react-datepicker__navigation--next {
    @apply mt-2;
}

.react-datepicker__day--outside-month {
    @apply text-gray-300;
}

.react-datepicker__day--selected {
    @apply rounded-md text-white;
}

.react-datepicker__input-container [type=text] {
    @apply base-form-input;
}

.base-form-input {
    @apply shadow-sm border border-gray-200 leading-6 rounded-md text-sm placeholder:text-black-light placeholder:opacity-50 w-full disabled:bg-gray-100;
}

.base-form-input:focus {
    @apply border-sky-blue outline-none ring-1 ring-sky-blue ring-inset;
}

.overflow-overlay {
    overflow: overlay;
}

/* INPUT STYLES */
/* input:focus {
  outline: none;
  border-color: "#0064ff";
} */

.no-focus-button:focus {
    box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

:focus {
    outline: none;
}

input {
    outline: none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

a[href*="http"] {
    @apply underline;
}

.gradient-blur::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    backdrop-filter: blur(0.5px);
    pointer-events: none;
}
